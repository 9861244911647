import React, { useState } from 'react';
import { Redirect } from 'react-router'
import { getProjectProgress } from '../../util'
import Headroom from '../Headroom'
import Stepper from './Stepper'
import { useSwipeable } from 'react-swipeable'
import PdfView from '../PdfViewer/index'
/* interface IProps extends any {
    projectIndex: number,
    project: IProject
}
 */

const ProjectLayout: React.FC<any> = (props) => {
    const { history, match, projectIndex, project } = props;

    const projectID = (match as any).params.projectID
    const currentPath = props.history.location.pathname

    const atHome = currentPath === "/"
    const [openPDF, setOpenPDF] = useState(false)


    const swipeConfig = {
        preventDefaultTouchmoveEvent: false,
        trackMouse: false
    }

    const swipeActions = {
        onSwipedRight: () => {
            if (atHome) {
                console.log('cannot go back')
            } else {
                history.back()
            }
        },
        onSwipedLeft: () => {
            history.forward()
        },

    }

    const handlers = useSwipeable({
        ...swipeConfig,
        ...swipeActions
    });


    if (project.details) {
        const { exteriorImage, floorPlanFile } = project.details
        const progress = getProjectProgress(project.details)
        const nextList = progress !== 100 ? `/project/all-lists/${projectID}` : `/project/submit/${projectID}`
        const map: { [key: string]: string } = {
            "/project/overview/:projectID": `/project/exterior/${projectID}`,
            "/project/exterior/:projectID": exteriorImage !== undefined && exteriorImage !== "" ? nextList : "",
            "/project/all-lists/:projectID": "",
            "/project/single-list/:checklistId/:projectID": nextList
        }

        const getNextStep = (matchPath: string) => {
            return map[matchPath]
        }

        const currentStep: string = props.match.path
        const nextStep = getNextStep(currentStep)


        return (
            <div className="flex-1 w-full">
                {floorPlanFile && <PdfView
                    url={floorPlanFile}
                    openPDF={openPDF}
                    close={() => setOpenPDF(false)}
                />}
                <div {...handlers}
                /* className="flex flex-col h-full bg-secondary" */
                >
                    <div className="p-4 pb-40">
                        {props.children}
                    </div>
                    {projectIndex !== -1 && (
                        <Headroom>
                            <Stepper
                                openPDF={() => setOpenPDF(true)}
                                history={props.history}
                                id={projectID}
                                currentStep={currentStep}
                                nextStep={nextStep}
                                projectIndex={projectIndex}
                                progress={progress ? progress : 0}
                            />
                        </Headroom>
                    )}
                </div>

            </div>
        )
    } else {
        return (
            <Redirect to="/" />
        )
    }

}

export default ProjectLayout