import { action } from 'typesafe-actions'
import { Reducer } from 'redux'
import { IProject, IProjectDetails } from '../types'

export const ProjectActions = {
    UPDATE_PROJECT_LISTS: '@@project/UPDATE_PROJECT_LISTS',
    UPDATE_PROJECT: '@@project/UPDATE_PROJECT',
    ADD_PROJECTS: '@@project/ADD_PROJECTS',
    UPDATE_PROJECT_DETAILS: '@@project/UPDATE_PROJECT_DETAILS',
    CLEAR_PROJECTS: '@@project/CLEAR_PROJECTS'
}


export const addProjects = (payload: IProject[]) => action(ProjectActions.ADD_PROJECTS, payload)

export const updateProject = (payload: IProject) => action(ProjectActions.UPDATE_PROJECT, payload)

export const updateProjectDetails = (payload: IUpdateDetailsPayload) => action(ProjectActions.UPDATE_PROJECT_DETAILS, payload)

export const clearProjects = () => action(ProjectActions.CLEAR_PROJECTS)

export const projectsReducer: Reducer<IProject[]> = (state: IProject[] = [], action) => {
    let updatedProjects: IProject[] = []
    switch (action.type) {
        case ProjectActions.ADD_PROJECTS:
            updatedProjects = [...state, ...action.payload]

            return updatedProjects

        case ProjectActions.UPDATE_PROJECT:
            const indexUpdate = state.findIndex(item => item.summary.projectID === action.payload.summary.projectID)

            updatedProjects = [
                ...state.slice(0, indexUpdate),
                action.payload,
                ...state.slice(indexUpdate + 1)
            ]
            return updatedProjects

        case ProjectActions.UPDATE_PROJECT_DETAILS:
            const indexUpdateDetails = state.findIndex(item => item.summary.projectID === action.payload.projectID)
            const originalItem = { ...state[indexUpdateDetails] }
            originalItem.details = action.payload.projectDetails
            updatedProjects = [
                ...state.slice(0, indexUpdateDetails),
                originalItem,
                ...state.slice(indexUpdateDetails + 1)
            ]
            return updatedProjects

        case ProjectActions.CLEAR_PROJECTS:
            return []

        default:
            return state
    }
}

export interface IUpdateDetailsPayload { projectID: string | number, projectDetails: IProjectDetails }