
import { createStore, Store } from 'redux'
import { History } from 'history'
import { IRootReducer, createRootReducer } from './reducers'

export default function configureStore(
  history: History,
  initialState: IRootReducer
): Store<IRootReducer> {

  const reducer = createRootReducer(history)
  // We'll create our store with the combined reducers/sagas, and the initial Redux state that
  // we'll be passing from our entry point.
  const store = createStore(
    reducer,
    initialState
  )

  return store
}
