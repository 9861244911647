import * as React from "react"
import { useDispatch } from "react-redux";

import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button';

import CheckCircle from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'

import logoImage from '../static/NBK_logo_WH.png'
import { logUserIn } from '../store/user'
import jwtService from '../services/jwtService'

interface IUserInfoState {
    [key: string]: string | undefined
    name: string | undefined
    password: string | undefined
}

type IStatus = "success" | "error" | "wait" | "form"

export const Login: React.FC = () => {
    const dispatch = useDispatch()
    const [userInfo, setUserInfo] = React.useState<IUserInfoState>({ name: undefined, password: undefined })
    const [status, setStatus] = React.useState<IStatus>("form")
    const [errorMessage, setErrorMessage] = React.useState<string>("")

    const handleRetry = () => {
        setStatus("form")
        setUserInfo({ name: undefined, password: undefined })
    }

    const handleChange = (name: string, e: any) => {
        const value: string = e.target.value
        setUserInfo({ ...userInfo, [name]: value })
    }

    const handleSubmit = () => {
        const { name, password } = userInfo
        setStatus("wait")
        if (name && password) {
            return jwtService.signInWithEmailAndPassword(name, password).then(res => {
                console.log(res)
                if (res.isLoggedIn) {
                    setUserInfo({ name: undefined, password: undefined })
                    setStatus("success")
                    setTimeout(() => {
                        dispatch(logUserIn())
                    }, 200)
                } else {
                    setStatus("error")
                    setErrorMessage("Something went wrong")
                }
            })
                .catch((error) => {
                    console.log(error)
                    setStatus("error")
                    setErrorMessage(error.message)
                })
        } else {
            setStatus("error")
            setErrorMessage("Fill in username and password")

        }

    }

    const loginForm = (
        <div className="w-4/5">
            <TextField
                id="standard-name"
                label="Name"
                value={userInfo.name}
                onChange={(e: any) => handleChange('name', e)}
                margin="normal"
                variant="filled"
                className="w-full"
            />
            <TextField
                id="standard-password"
                label="Password"
                type="password"
                value={userInfo.password}
                onChange={(e: any) => handleChange('password', e)}
                margin="normal"
                variant="filled"
                className="w-full"
            />
            <Button
                onClick={handleSubmit}
                className="w-full text-center bg-white">
                Log in
            </Button>
        </div>
    )

    const showContent = (status: IStatus) => {
        switch (status) {
            case "form":
                return loginForm
            case "success":
                return (
                    <CheckCircle />
                )
            case "error":
                return (
                    <div className="flex flex-col items-center">
                        <ErrorIcon />

                        <Typography
                            onClick={() => { handleRetry() }}
                            className="cursor-pointer underline">
                            Prøv igjen. {errorMessage}
                        </Typography>
                    </div>
                )
            case "wait":
                return (
                    <CircularProgress />
                )
            default:
                return loginForm
        }
    }

    const content = showContent(status)
    return (
        <div className="flex-1 h-full">
            <div
                style={{ background: `linear-gradient(135deg, #3dccc4 0, #0d8b84 100%)` }}
                className="h-full flex flex-col justify-center items-center "
            >
                <div className="w-3/5 sm:w-64 py-4">
                    <img alt="logo" className="w-full" src={logoImage} />
                </div>
                {content}
            </div>
        </div>
    )
}

export default Login