import React from 'react';
import Button from '@material-ui/core/Button';

import LinearProgress from '@material-ui/core/LinearProgress';
import Fab from '@material-ui/core/Fab';

import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import DoneAll from '@material-ui/icons/Done';
import Home from '@material-ui/icons/Home';
import PDF from '@material-ui/icons/PictureAsPdf';
import List from '@material-ui/icons/ListAlt';


interface IProp {
    history: any
    projectIndex: number
    currentStep: string
    nextStep: string
    id: string
    progress: number
    openPDF: () => void
}

const ProgressMobileStepper: React.FC<IProp> = ({ history, id, currentStep, nextStep, progress, openPDF }) => {

    const showNext = currentStep !== "/project/submit/:projectID" && currentStep !== "/project/all-lists/:projectID"
    const hasNext = nextStep !== "" && nextStep !== undefined
    const hideMenu = currentStep === "/project/overview/:projectID" || currentStep === "/project/exterior/:projectID"

    return (
        <div className="w-full flex-1 relative">

            {showNext ? (
                <div
                    className="text-color absolute"
                    style={{
                        top: "-75px",
                        right: "10px"
                    }}
                >
                    <Fab
                        color={hasNext ? "primary" : "secondary"}

                        onClick={() => {
                            if (hasNext) history.push(nextStep)
                        }}>
                        <KeyboardArrowRight />
                    </Fab>
                </div>
            ) : null}

            <LinearProgress
                variant="determinate"
                color="primary"
                value={progress}
            />

            {hideMenu ? null : (
                <div className="flex py-2">
                    <div className="flex-1 flex flex-col justify-start items-center">
                        <Button
                            onClick={() => history.push(`/project/overview/${id}`)}
                            color="secondary"
                            size="small" >
                            <Home />

                        </Button>
                        <p>Info</p>
                    </div>
                    <div className="flex-1 flex flex-col justify-start items-center" style={{ flex: 3 }} onClick={openPDF}>
                        <Button
                            color="secondary"
                            size="small"
                        >
                            <PDF />
                        </Button>
                        <p>Plantegning</p>
                    </div>
                    <div
                        onClick={() => history.push(`/project/all-lists/${id}`)}
                        className="flex-1 flex flex-col justify-start items-center"
                        style={{ flex: 3 }}
                    >
                        <Button
                            color="secondary"
                            size="small"
                        >
                            <List />
                        </Button>
                        <p>Sjekklister</p>
                    </div>
                    <div
                        className="flex-1 flex flex-col justify-start items-center"
                        onClick={() => { if (progress === 100) { history.push(`/project/submit/${id}`) } }}
                    >
                        <Button
                            color="secondary"
                            size="small"
                        >
                            <DoneAll />
                        </Button>
                        <p>Klar</p>
                    </div>
                </div>
            )}
        </div>

    );
}


export default ProgressMobileStepper;