import * as React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import { IProject } from '../types'
import { projectsSelector } from '../store/selector'
import { setActiveProject } from '../store/activeProject'
import ProjectLayout from '../Components/ProjectLayout'


const WithLayoutRoute: React.FC<any> = ({ component: Component, ...rest }) => {
    const projects = useSelector(projectsSelector);
    const dispatch = useDispatch()
    const { projectID } = (rest.computedMatch.params as any)
    const index = projects.findIndex((p: IProject) => p.summary.projectID === projectID)
    dispatch(setActiveProject(index))
    return index !== -1 ? (
        <Route
            {...rest}
            render={(props) => (
                <ProjectLayout {...props} projectIndex={index} project={projects[index]}>
                    <Component {...props} projectIndex={index} project={projects[index]} />
                </ProjectLayout>
            )}
        />
    ) : <Redirect to="/" />
}

export default WithLayoutRoute