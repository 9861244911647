import { IChecklist, IChecklistItem } from '../types'

export const persistProject = (projectID: string, projectChecklists: IChecklist[]) => {

    projectChecklists.map(list => {

        const updatedItems = list.checkItems.map(item => {
            const itemKey = `nks_projects_${projectID}_${item.checklistItemId}`
            sessionStorage.setItem(itemKey, JSON.stringify(item));
            return item
        })
        list.checkItems = updatedItems
        return list
    })
}

export const getPersistProject = (projectID: string, projectChecklists: IChecklist[]) => {

    const updatedChecklists = projectChecklists.map(list => {

        list.checkItems = list.checkItems.map(item => {

            const localItem = sessionStorage.getItem(`nks_projects_${projectID}_${item.checklistItemId}`)
            if (localItem) {
                const updateItem: IChecklistItem = JSON.parse(localItem)
                return updateItem
            } else {
                return item
            }
        })
        return list
    })

    return updatedChecklists
}

export const deletePersistProject = (projectID: string) => {
    for (var i = 0, len = sessionStorage.length; i < len; ++i) {
        const itemKey = sessionStorage.key(i)
        const matchString = `nks_projects_${projectID}`
        if (itemKey && itemKey.startsWith(matchString)) {
            sessionStorage.removeItem(itemKey)
        }
    }
}