import { action } from 'typesafe-actions'
import { Reducer } from 'redux'


export const LoginActionTypes = {
        LOGIN_USER: '@@user/LOGIN_USER',
        LOGOUT_USER: '@@user/LOGOUT_USER'
}
export const logUserIn = () => action(LoginActionTypes.LOGIN_USER)
export const logUserOut = () => action(LoginActionTypes.LOGOUT_USER)
export interface IUser { isLoggedIn: boolean }
export const userReducer: Reducer<IUser> = (state: IUser = { isLoggedIn: false }, action) => {
        switch (action.type) {
                case '@@user/LOGIN_USER':
                        return { isLoggedIn: true }

                case '@@user/LOGOUT_USER':
                        return { isLoggedIn: false }

                default:
                        return state
        }
}