import "./pdfview.css"

import React, { Component, useState, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";

import Close from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import ErrorIcon from '@material-ui/icons/Error';
import LeftArrow from '@material-ui/icons/KeyboardArrowLeft';
import RightArrow from '@material-ui/icons/KeyboardArrowRight';
import RotateRight from '@material-ui/icons/RotateRight';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
/* 
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`; */

interface IPdfViewer {
    openPDF: boolean
    url: string | Blob | ArrayBuffer
    close: () => void
}
interface IOnLoadSuccess {
    numPages: number
}
function debounce(fn: Function, ms: number) {
    let timer: any
    const _: any = () => {
        clearTimeout(timer)
        timer = setTimeout(() => {
            timer = null
            //@ts-ignore
            fn.apply(this, arguments)
        }, ms)
    };
    return _
}

const PdfViewer: React.FC<IPdfViewer> = ({ openPDF, url, close }) => {
    const [numOfPages, setNumOfPages] = useState<null | number>(null)
    const [pageNumber, setPageNumber] = useState(0)
    const [rotate, setRotate] = useState(0)
    const [width, setWidth] = useState(0)
    const [height, setHeight] = useState(0)

    function handleResize() {
        const width = window.innerWidth
        const height = window.innerHeight
        setHeight(height)
        setWidth(width)
    }

    useEffect(() => {
        const debouncedHandleResize = debounce(handleResize, 300)
        window.addEventListener('resize', debouncedHandleResize)

        return () => {
            window.removeEventListener('resize', debouncedHandleResize)
        }
    })

    const onDocumentLoadSuccess = (data: IOnLoadSuccess) => {
        setNumOfPages(data.numPages);
    }

    const goToPrevPage = () => {
        if (pageNumber > 1) {
            setPageNumber(pageNumber - 1)
        }
    }

    const goToNextPage = () => {
        if (numOfPages && numOfPages - pageNumber > 0) {
            setPageNumber(pageNumber + 1)
        }
    }

    return (
        <div
            className="fixed flex-col justify justify-between items-center z-20 w-full h-full"
            style={{ display: openPDF ? "flex" : "none", background: "rgba(0,0,0,0.8)" }}
        >
            <Close
                onClick={close}
                className="fixed z-30 text-white"
                style={{ right: "10px" }}
            />

            <Document
                file={url}
                error={<ErrorIcon color="error" />}
                loading={<CircularProgress />}
                onLoadSuccess={onDocumentLoadSuccess}
                onLoadError={console.error}
                rotate={rotate}
            >
                <div className="w-full flex justify-around">
                    <Typography color="primary" variant="caption">
                        Page {pageNumber} of {numOfPages}
                    </Typography >
                </div>
                <div className="w-full flex justify-around">
                    <Button color="primary" onClick={goToPrevPage}><LeftArrow /> Prev</Button>
                    <Button color="primary" onClick={() => { setRotate(rotate + 90) }}>
                        <RotateRight /> Rotate
                    </Button>
                    <Button
                        color="primary"
                        onClick={goToNextPage}>
                        Next <RightArrow />
                    </Button>
                </div>

                <Page pageNumber={pageNumber} width={width} height={height} />
            </Document>
        </div>
    )


}

export default PdfViewer