import React from 'react';
import jwtService from './services/jwtService'
import { useDispatch } from "react-redux";
import { logUserIn } from './store/user'

const Auth: React.FC = ({ children }) => {
  const dispatch = useDispatch()
  React.useEffect(() => {
    jwtService.init()
    jwtService.signInWithToken()
      .then(res => {
        dispatch(logUserIn())
      })
      .catch(err => {
        console.log(err)
      })
  }, [])
  return <>{children}</>
}

export default Auth;
