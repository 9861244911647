import { combineReducers, Dispatch, Action, AnyAction } from 'redux'
import { IProject } from '../types'
import { projectsReducer } from './project'
import { userReducer, IUser } from './user'
import { activeProjectReducer } from './activeProject'
/* import { composeWithDevTools } from 'redux-devtools-extension' */
import { History } from 'history'

export interface IRootReducer {
    user: IUser,
    projects: IProject[],
    activeProjectIndex: number | null

}

export interface ConnectedReduxProps<A extends Action = AnyAction> {
    dispatch: Dispatch<A>
}

export const createRootReducer = (history: History) => combineReducers({
    activeProjectIndex: activeProjectReducer,
    projects: projectsReducer,
    user: userReducer,
})