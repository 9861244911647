import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { Divider } from '@material-ui/core';
import NavIcon from '@material-ui/icons/Menu';
import { logUserOut as logUserOutAction } from '../store/user'
import { clearProjects as clearProjectsAction } from '../store/project'
import JwtService from '../services/jwtService';

interface IProp {
    title: string,
    description?: string,
    history: any,
}

const ProjectTitle: React.FC<IProp> = ({ title, description, history }) => {
    const dispatch = useDispatch()
    const [anchorEl, setAnchorEl] = useState<any>(null)
    const onClickMenu = (event: any) => setAnchorEl(event.currentTarget)
    const onCloseMenu = () => setAnchorEl(null)

    const handleLogout = () => {

        JwtService.logout()
        dispatch(logUserOutAction())
        dispatch(clearProjectsAction())
    }
    return (
        <div className="flex justify-between items-start pb-4">
            <div>
                <h1 className="text-primary font-semibold mb-2">{title}</h1>
                <p className="text-gray-600 mb-2">{description}</p>
            </div>
            <Button
                color="secondary"
                aria-owns={anchorEl ? 'simple-menu' : undefined}
                aria-haspopup="true"
                onClick={onClickMenu}

            >
                <NavIcon color="primary" />
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={onCloseMenu}
            >
                <MenuItem onClick={() => {
                    onCloseMenu()
                    history.push("/")
                }} >
                    Alle prosjekter
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleLogout}>
                    Logg ut
                </MenuItem>
            </Menu>
        </div>)
}

export default ProjectTitle