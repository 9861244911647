import * as React from 'react'
import AllProjects from '../Screens/AllProjects'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import ProjectRoutes from './ProjectRoutes'

export default () => {
    return (
        <Router>
            <Switch>
                <Route exact path="/" component={AllProjects} />
                <Route path="/project" component={ProjectRoutes} />
                <Route component={AllProjects} />
            </Switch>
        </Router>
    )
}



