import React from 'react'

import { RouteComponentProps } from 'react-router'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import ProjectTitle from '../Components/ProjectTitle'

import { IProject } from '../types'

interface IProps extends RouteComponentProps {
    projectIndex: number,
    history: any
    project: IProject
}

const Projects: React.FC<IProps> = ({ history, project }) => {

    const { projectID } = project.summary
    const { checklists } = project.details

    return (
        <div>
            <ProjectTitle title={"Velg ett av følgende"} history={history} />
            {checklists.map((item, key) =>
                <Card
                    className="mb-4 p-0"
                    style={{ minWidth: 275 }}
                    key={key}
                    onClick={() => history.push(`/project/single-list/${item.checklistId}/${projectID}`)}
                >
                    <CardContent className="flex justify-space items-center p-0">
                        <div className="flex py-4" >
                            {item.checklistName}
                        </div>
                        <p className="text-gray-600">{item.checkItems.filter(item => item.status !== undefined && item.status !== null).length}/{item.checkItems.length}</p>
                    </CardContent>
                </Card>
            )}
        </div>
    )
}

export default Projects