import React, { useEffect, useState } from 'react'

import DatePicker from 'react-mobile-datepicker';
import Card from '@material-ui/core/Card';
import { RouteComponentProps, Redirect } from 'react-router'
import { createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import DateRange from '@material-ui/icons/DateRange';
import Place from '@material-ui/icons/Place';
import Phone from '@material-ui/icons/Phone';
import Menu from '@material-ui/core/Menu';

import MenuItem from '@material-ui/core/MenuItem';
import { IProject } from '../types'
import { Divider } from '@material-ui/core';
import ProjectTitle from '../Components/ProjectTitle'
import { dateToString, updateProfile } from '../util'
import { useDispatch } from 'react-redux';
import { updateProject } from '../store/project'
import { CopyToClipboard } from 'react-copy-to-clipboard';

interface IProps extends RouteComponentProps {
    /*     classes: any */
    projectIndex: number
    project: IProject
    /*     updateProject: (payload: IProject) => void */
}

//// redux load project
export const ProjectDetails: React.FC<IProps> = (props) => {
    const dispatch = useDispatch()
    const { history, project } = props
    const [copiedText, setCopiedText] = useState(false)
    const [openDatePicker, setDatePicker] = useState(false)
    const [newDate, setDate] = useState<Date>(new Date())
    const [openEditDes, setEditDes] = useState(false)
    const [anchorEl, setAnchorEl] = useState<any>(null)

    useEffect(() => {
        if (project.details) {
            const { inspectionDate } = project.summary
            const parsedDate = new Date(inspectionDate)
            setDate(parsedDate)
        }
    }, [project.details, project.summary])

    if (project.details) {

        const { projectName, projectDetail, inspectionDate, projectID } = project.summary
        const { address, projectLeader, flisLegger, exteriorImage } = project.details

        const onClickMenu = (event: any) => setAnchorEl(event.currentTarget)

        const onCloseMenu = () => {
            setAnchorEl(null)
            setCopiedText(false)
        }

        const updateDescription = (e: any) => {
            const value = e.target.value
            const toUpdateProject = { ...project }
            toUpdateProject.summary.projectDetail = value
            dispatch(updateProject(toUpdateProject))
        }

        const submitDescription = () => {
            setEditDes(!openEditDes)
            return updateProfile(inspectionDate, projectDetail, projectID, exteriorImage)
        }

        const updateDate = (date: Date) => {
            setDate(date)
            setDatePicker(false)
            const toUpdateProject = { ...project }
            toUpdateProject.summary.inspectionDate = dateToString(date)
            updateProject(toUpdateProject)

            return updateProfile(date.toISOString(), projectDetail, projectID, exteriorImage)

        }

        const handleCopy = () => {
            setCopiedText(true)
        }

        const classes = {
            rowItem: "flex items-start py-1 justify-between",
            leftContent: "flex",
            icon: "pr-2"
        }
        return (
            <div>
                <ProjectTitle title={projectName} history={history} />
                <Card className="mb-4 py-2 px-4">
                    {[{
                        icon: <DateRange color="secondary" />,
                        label: undefined,
                        text: dateToString(newDate),
                        button: (
                            <Button
                                color="secondary"
                                onClick={() => setDatePicker(true)}
                            >
                                Rediger
                            </Button>
                        )
                    },
                    {
                        icon: <Place color="secondary" />,
                        label: undefined,
                        text: address,
                        button: (
                            <Button
                                color="secondary"
                                aria-owns={anchorEl ? 'simple-menu' : undefined}
                                aria-haspopup="true"
                                onClick={onClickMenu}

                            >
                                Kart
                            </Button>
                        )
                    }].map((item, i) => (
                        <div key={i}>
                            {i !== 0 && <Divider />}
                            <div className={classes.rowItem}>
                                <div className={classes.leftContent} >
                                    <div className={classes.icon}>
                                        {item.icon}
                                    </div>

                                    <div className="text-primary">
                                        {item.text}
                                    </div>
                                </div>
                                {item.button}
                            </div>
                        </div>)

                    )}
                    <Divider />
                    {[{
                        icon: <Phone color="secondary" />,
                        label: "Prosjektleder",
                        text: projectLeader.name,
                        button: (
                            <Button
                                color="secondary"
                                href={`tel:${projectLeader.number}`}
                            >
                                Ring
                            </Button>
                        )
                    }, {
                        icon: <Phone color="secondary" />,
                        label: "UTF Våtrom",
                        text: flisLegger.name,
                        button: (
                            <Button
                                color="secondary"
                                href={`tel:${flisLegger.number}`}
                            >
                                Ring
                            </Button>
                        )
                    }
                    ].map((item, i) => (
                        <div key={i}>
                            {i !== 0 && <Divider />}
                            <div className={classes.rowItem}>
                                <div className={classes.leftContent}>
                                    <div className={classes.icon}>
                                        {item.icon}
                                    </div>

                                    <div>
                                        <Typography variant="caption" color="textSecondary">{item.label}</Typography>
                                        <Typography variant="subtitle1" color="textSecondary">{item.text}</Typography>
                                    </div>
                                </div>
                                {item.button}
                            </div>
                        </div>)

                    )}
                </Card>
                <div className={classes.rowItem}>
                    <div className={"mb-2"}>
                        <Typography variant="caption" color="textSecondary">Beskrivelse</Typography>
                    </div>
                    <Button color="secondary" onClick={submitDescription}>Rediger</Button>
                </div>
                <textarea
                    rows={4}
                    disabled={!openEditDes}
                    onChange={updateDescription}
                    value={projectDetail}
                    className={`w-full border border-solid border-gray-400 px-2 ${openEditDes ? '' : 'border-none bg-transparent'}`}
                />
                {/* <Typography variant="caption" color="textSecondary">Swipe to continue >></Typography> */}
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={onCloseMenu}
                >
                    <CopyToClipboard onCopy={handleCopy} text={address}><MenuItem onClick={onCloseMenu}>Copy {copiedText && 'copied'}</MenuItem></CopyToClipboard>
                    <MenuItem onClick={onCloseMenu}><a className="no-underline" href={`https://www.google.com/maps/search/?api=1&query=${encodeURI(address)}`}>Open in Google Maps</a></MenuItem>
                    <MenuItem onClick={onCloseMenu}><a className="no-underline" href={`http://maps.apple.com/?q=${encodeURI(address)}`}>Open in Maps</a></MenuItem>
                </Menu>

                <DatePicker
                    dateConfig={{
                        'year': {
                            format: 'YYYY',
                            caption: 'Year',
                            step: 1,
                        },
                        'month': {
                            format: 'MM',
                            caption: 'Mon',
                            step: 1,
                        },
                        'date': {
                            format: 'DD',
                            caption: 'Day',
                            step: 1,
                        },
                        'hour': {
                            format: 'hh',
                            caption: 'Hour',
                            step: 1,
                        },
                        'minute': {
                            format: 'mm',
                            caption: 'Min',
                            step: 1,
                        }
                    }}
                    confirmText="Set dato"
                    cancelText="Avlyst"

                    value={newDate}
                    isOpen={openDatePicker}
                    onSelect={(time: Date) => updateDate(time)}
                    onCancel={() => setDatePicker(false)}
                />
            </div>
        )
    } else {
        return <Redirect to="/" />
    }

}



export default ProjectDetails