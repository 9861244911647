import React, { Component } from 'react';
import { Webcam } from './WebCam.js';
import Fab from '@material-ui/core/Fab';
import PhotoIcon from '@material-ui/icons/PhotoCamera';
import Close from '@material-ui/icons/Close';
import Done from '@material-ui/icons/Done';
import Typography from '@material-ui/core/Typography';
import { withStyles, createStyles } from '@material-ui/core/styles';
interface IProps {
    takeOne: boolean
    close: () => void
    submit: (imgs: string[]) => void,
}
interface IState {
    capturedImages: string[]
}

class NBKCamera extends Component<IProps, IState> {
    webcam: Webcam | null
    canvasElement: HTMLCanvasElement | null = null
    constructor(props: any) {
        super(props);
        this.webcam = null
        this.state = {
            capturedImages: []
        }
        this.off = this.off.bind(this)
    }

    componentDidMount() {

        this.canvasElement = document.createElement('canvas');
        this.webcam = new Webcam(
            document.getElementById('webcam'),
            this.canvasElement
        );
        this.webcam.setup().catch((error) => {
            alert(error.message);
        });

    }

    componentWillUnmount() {
        this.props.submit(this.state.capturedImages)
        if (this.webcam !== null) {
            this.webcam.switchOff()
        }
    }

    captureImage = async () => {
        if (this.webcam !== null) {
            const capturedData = this.webcam.takeBase64Photo({ type: 'jpeg', quality: 0.8 });
            const capturedImages = [...this.state.capturedImages, capturedData.base64]
            this.setState({ capturedImages });
        }
    }

    off() {
        if (this.webcam !== null) {
            this.webcam.switchOff()
        }
    }

    render() {
        return (
            <div className="fixed h-full w-full z-10 bg-black -m-4">
                <div className="absolute z-20 p-4">
                    <img
                        className="z-20 fixed w-1/3 h-auto"
                        src={this.state.capturedImages[this.state.capturedImages.length - 1]}
                        alt="" />
                    {this.state.capturedImages.length > 0 ? (
                        <Typography
                            className="text-white"
                            style={{ bottom: "4rem", left: "10px" }}
                        >
                            {this.state.capturedImages.length}
                        </Typography>
                    ) : null}
                </div>
                <Close onClick={this.props.close} className="fixed z-20 bg-white" style={{ top: "10px", right: "10px" }} />
                <div className="absolute left-0 right-0 bottom-0 w-full flex justify-around z-20 pb-8 px-8">
                    {this.state.capturedImages.length > 0 ? (
                        <Fab

                            style={{ margin: "0 0 0 8px" }}
                            onClick={this.props.close}
                        >
                            <Done />
                        </Fab>
                    ) : null
                    }
                    <Fab onClick={this.captureImage}>
                        <PhotoIcon />
                    </Fab>

                </div>
                <div className="absolute inset-0 flex items-center">
                    <video
                        autoPlay
                        playsInline
                        muted
                        id="webcam"
                        width="auto"
                        height="100%"
                        className="w-full h-auto"
                    />
                </div>

            </div>
        )
    }
}

export default NBKCamera

