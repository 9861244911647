
import axios from 'axios'
import allRequests from '../api'

import { IProjectDetails, IChecklistItem } from '../types'

export const doubleDigit = (d: number) => {
    return `${d < 10 ? '0' : ''}${d}`
}
export const dateToString = (time: Date) => {
    return `${time.getFullYear()}.${doubleDigit(time.getMonth() + 1)}.${doubleDigit(time.getDate())} ${doubleDigit(time.getHours())}:${doubleDigit(time.getMinutes())} `
}

export const postRequestDateToString = (time: Date) => {
    return `${doubleDigit(time.getDate())}/${doubleDigit(time.getMonth() + 1)}/${time.getUTCFullYear()} ${doubleDigit(time.getHours())}:${doubleDigit(time.getMinutes())}:${doubleDigit(time.getSeconds())}`
}

export const getProjectProgress = (project: IProjectDetails) => {
    let totalCount = 0
    let completedCount = 0
    totalCount++;
    if (project.exteriorImage !== undefined && project.exteriorImage !== "") {
        completedCount++
    }
    project.checklists.forEach((item) => {
        item.checkItems.forEach((clItem) => {
            totalCount++
            if (clItem.status !== undefined && clItem.status !== null) {
                completedCount++
            }
        })
    })
    if (completedCount === 0) {
        return 0
    } else if (totalCount !== 0) {
        return Math.floor((completedCount / totalCount) * 100)
    }

}

export function dataURItoBlob(dataURI: string) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
    else
        byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
}


export const updateProfile = async (inpectionDate: string, description: string, projectID: string, exteriorImage: string | undefined) => {

    const toSend = {
        "ProjectUpdate": {
            "ProjectId": projectID,
            "ProjectDate": inpectionDate,
            "ProjectDescription": description
        }
    }
    const formData = new FormData();

    formData.append('request', JSON.stringify(toSend))

    if (exteriorImage) {
        let image: string | Blob = exteriorImage;
        if (image.startsWith('data:')) {
            image = dataURItoBlob(image)
        } else if (image.startsWith('http')) {
            image = await fetch(image).then(res => res.blob())
        }
        formData.append('image', image)
    }

    return axios.post(allRequests.updateProject.url, formData, {
        headers: {
            'Content-Type': 'application/json'
        }
    })

}

interface IUpdateChecklistItem {
    projectID: string
    checklistId: string
    checklistItem: IChecklistItem
    dispatch?: any
}


export const updateChecklistItem = async (item: IUpdateChecklistItem) => {
    const { projectID, checklistId, checklistItem } = item
    const { checklistItemId, itemImageUrls, comment, status } = checklistItem
    const toSend = {
        "ChecklistItem": {
            "ProjectId": projectID,
            "CheckListID": checklistId,
            "QuestionID": checklistItemId,
            "Status": status,
            "Comment": comment
        }
    }

    const formData = new FormData();
    formData.append('request', JSON.stringify(toSend))

    if (itemImageUrls && itemImageUrls.length > 0) {
        /*  formData.append('itemImageUrls', JSON.stringify(itemImageUrls)) */
        for (const index in itemImageUrls) {

            let image: string | Blob = itemImageUrls[index]
            if (image.startsWith('data:')) {
                image = dataURItoBlob(image)
            } else if (image.startsWith('http')) {
                image = await fetch(image).then(res => res.blob())
            }
            formData.append(`image${index}`, image)
        }


    }

    return axios.post(allRequests.updateChecklist.url, formData, {
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(res => res)
}
