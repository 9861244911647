
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import allRequests from '../../api'
/* eslint-disable camelcase */
class EventEmitter {
constructor() {
	this.events = {};
}

_getEventListByName(eventName) {
	if (typeof this.events[eventName] === 'undefined') {
		this.events[eventName] = new Set();
	}
	return this.events[eventName];
}

on(eventName, fn) {
	this._getEventListByName(eventName).add(fn);
}

once(eventName, fn) {
	const self = this;

	const onceFn = (...args) => {
		self.removeListener(eventName, onceFn);
		fn.apply(self, args);
	};
	this.on(eventName, onceFn);
}

emit(eventName, ...args) {
	this._getEventListByName(eventName).forEach(
		// eslint-disable-next-line func-names
		function (fn) {
			fn.apply(this, args);
		}.bind(this)
	);
}

removeListener(eventName, fn) {
	this._getEventListByName(eventName).delete(fn);
}
}

console.log(process.env.REACT_APP_API_URL)

class JwtService extends EventEmitter {
	init() {
		this.setInterceptors();
		this.handleAuthentication();
	}

	setInterceptors = () => {
		axios.interceptors.request.use(function (config) {
			const { url, ...rest } = config;
			const updated = {
				...rest,
				url: `${process.env.REACT_APP_API_URL}/api${url}`
			};
			console.log(updated)
			return updated;
		});
		axios.interceptors.response.use(
			response => response,
			err => {
				console.log(err)
				if (err.response && err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
					// if you ever get an unauthorized response, logout the user
					this.emit('onAutoLogout', 'Invalid access_token');
					this.setSession(null);
					
				}
				return Promise.reject(err);
			}
		);
	};

	handleAuthentication = () => {
		const access_token = this.getAccessToken();
		const user_id = this.getSessionUserId();
		if (!access_token) {
			this.emit('onNoAccessToken');

			return;
		}

		if (this.isAuthTokenValid(access_token)) {
			this.setSession(access_token, user_id);
			this.emit('onAutoLogin', true);
		} else {
			this.setSession(null);
			this.emit('onAutoLogout', 'access_token expired');
		}
	};

	signInWithEmailAndPassword = (email, password) => {
		return new Promise((resolve, reject) => {
			const url = allRequests.login.url;

			const options = {
				url,
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				data: {
					UserName: email,
					Password: password
				}
			};

			return axios(options).then(response => {
				console.log(response)
				if (response.data) {
					this.setSession(response.data.token, response.data.id);
					const user = response.data;
					const toReturn = {
						isLoggedIn:true,
						id: user.id,
						userName: user.userName,
						displayName: user.fullName,
					};

					resolve(toReturn);
				} else {
					reject(response.data.error);
				}
			}).catch(err=>{
				console.log(err)
				reject(err)
				return err
			});
		});
	};

	signInWithToken = () => {
		return new Promise((resolve, reject) => {
			const token = this.getAccessToken();
			const id = this.getSessionUserId();
			axios
				.get(allRequests.userProfile.url, {
					params: {
						UserProfileID: id
					},
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				.then(response => {
					if (response.data && response.data.userProfile) {
						const user = response.data.userProfile;
						const toReturn = {
							role: ['admin'], // guest
							data: {
								id: user.id,
								userName: user.userName,
								displayName: user.userName,
								photoURL: 'assets/images/avatars/Velazquez.jpg',
								email: 'johndoe@withinpixels.com',
								shortcuts: ['calendar', 'mail', 'contacts', 'todo']
							}
						};
						resolve(toReturn);
					} else {
						this.logout();
						reject(new Error('Failed to login with token.'));
					}
				})
				.catch(error => {
					this.logout();
					reject(new Error('Failed to login with token.'));
				});
		});
	};

	setSession = (access_token, id) => {
		if (access_token && id) {
			localStorage.setItem('session_user_id', id);
			localStorage.setItem('nks_jwt_access_token', access_token);
			axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
		} else {
			localStorage.removeItem('session_user_id');
			localStorage.removeItem('nks_jwt_access_token');
			delete axios.defaults.headers.common.Authorization;
		}
	};

	logout = () => {
		this.setSession(null);
	};

	isAuthTokenValid = access_token => {
		if (!access_token) {
			return false;
		}
		const decoded = jwtDecode(access_token);
		const currentTime = Date.now() / 1000;
		if (decoded.exp < currentTime) {
			console.warn('access token expired');
			return false;
		}

		return true;
	};

	getAccessToken = () => {
		return window.localStorage.getItem('nks_jwt_access_token');
	};

	getSessionUserId = () => {
		return window.localStorage.getItem('session_user_id');
	};
}

const instance = new JwtService();

export default instance;
