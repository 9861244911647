import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import configureStore from './store/configureStore'
import { IRootReducer } from './store/reducers'
import { IProjectDetails, IProject } from './types'
import { projectsSelectorState } from './store/selector'
import { createBrowserHistory } from 'history';

import { persistProject } from './util/persis'
export const history = createBrowserHistory()

const initialState: IRootReducer = {
  user: { isLoggedIn: false },
  projects: [],
  activeProjectIndex: null
}
let currentValue: IProject[]
let currentActiveIndex: number | null
const store = configureStore(history, initialState)
store.subscribe(() => {
  const storeValue = store.getState()
  let previousValue = currentValue
  let previousIndex = currentActiveIndex
  currentValue = projectsSelectorState(storeValue)
  currentActiveIndex = storeValue.activeProjectIndex
  const index = storeValue.activeProjectIndex

  const isPorjectUpdated = JSON.stringify(previousValue) !== JSON.stringify(currentValue)
  const isIndexUpdated = previousIndex !== currentActiveIndex
  if (typeof index === "number") {
    if (isPorjectUpdated || isIndexUpdated) {
      const project = storeValue.projects[index]
      if (project && project.summary && project.details) {
        persistProject(project.summary.projectID, project.details.checklists)
      }
    }
  }

})
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>

  </React.StrictMode>,
  document.getElementById('root')
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
