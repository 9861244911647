import { action } from 'typesafe-actions'
import { Reducer } from 'redux'
export const ActiveProjectTypes = {
    SET_INDEX: '@activeProject/SET_INDEX',
    UNSET_INDEX: '@activeProject/UNSET_INDEX'
}

export const setActiveProject = (payload: number) => action(ActiveProjectTypes.SET_INDEX, payload)

export const unsetActiveProject = () => action(ActiveProjectTypes.UNSET_INDEX)

export const activeProjectReducer: Reducer<number | null> = (state: number | null = null, action) => {
    switch (action.type) {
        case ActiveProjectTypes.SET_INDEX:
            return action.payload

        case ActiveProjectTypes.UNSET_INDEX:
            return null
        default:
            return state
    }
}