import React, { useState } from 'react'
import { IProject, IChecklistItem } from '../types'
import shortid from 'shortid'
import { RouteComponentProps, Redirect } from 'react-router'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import FormControl from '@material-ui/core/FormControl';
import PhotoCamera from '@material-ui/icons/PhotoCamera'
import Close from '@material-ui/icons/Close'
import ProjectTitle from '../Components/ProjectTitle'
import { useDispatch } from 'react-redux';
import { updateChecklistItem } from '../util'
import { updateProject as updateProjectAction } from '../store/project'

import { Divider } from '@material-ui/core';
import NBKCamera from '../Components/NBKCamera'


interface IProps extends RouteComponentProps {

    projectIndex: number
    project: IProject
}


export const CheckLists: React.FC<IProps> = ({ history, match, projectIndex, project }) => {
    const dispatch = useDispatch()
    const { checklistId } = (match.params as any)
    const [openCam, setOpenCam] = useState(false);
    const [imagesForItem, setImagesFor] = useState<null | IChecklistItem>(null);
    if (project.details !== undefined) {
        const { checklists } = project.details
        const clIndex = checklists.findIndex(c => c.checklistId === checklistId)
        const list = checklists[clIndex];

        const handleClose = () => {
            setOpenCam(false)
            setImagesFor(null)
        }

        const updateChecklist = (clIndex: number, clItemIndex: number, clItem: IChecklistItem) => {
            const toUpdateProject = { ...project }
            toUpdateProject.details.checklists[clIndex].checkItems[clItemIndex] = clItem
            dispatch(updateProjectAction(toUpdateProject))
            if (navigator.onLine) {

                updateChecklistItem({ projectID: project.summary.projectID, checklistId: list.checklistId, checklistItem: clItem })
                    .then((res) => {
                        toUpdateProject.details.checklists[clIndex].checkItems[clItemIndex] = { ...clItem, updated: true }
                        dispatch(updateProjectAction(toUpdateProject))
                    })
            }
        }

        const handleStatusChange = (e: any, clItemId: string) => {
            console.log('status change for ' + clItemId)
            const result = e.target.value//onClick={() => { if (box.option !== "NA") {  } else { } }}

            const clItemIndex = list.checkItems.findIndex(clItem => clItem.checklistItemId === clItemId)
            if (clItemIndex !== -1) {
                const originalItem = list.checkItems[clItemIndex];
                if (originalItem) {
                    const toUpdateClItem = { ...originalItem, status: result }
                    if (result !== "NA") {
                        setOpenCam(true)
                        setImagesFor(toUpdateClItem)
                    } else {
                        toUpdateClItem.itemImageUrls = []
                        updateChecklist(clIndex, clItemIndex, toUpdateClItem)
                    }

                }
            }

        }

        const handleSubmitImage = (images: string[]) => {
            const clItemIndex = list.checkItems.findIndex(clItem => clItem.checklistItemId === imagesForItem?.checklistItemId)
            if (clItemIndex !== -1 && imagesForItem && imagesForItem?.checklistItemId) {
                const originalItem = { ...imagesForItem };
                const allImages = originalItem.itemImageUrls ? originalItem.itemImageUrls : [];
                allImages.push(...images)
                if (images.length > 0) {
                    const toUpdateClItem = { ...originalItem, itemImageUrls: allImages }
                    updateChecklist(clIndex, clItemIndex, toUpdateClItem)
                }
            }
        }

        const onCommentChange = (text: string, clItemId: string) => {

            const clItemIndex = list.checkItems.findIndex(clItem => clItem.checklistItemId === clItemId)
            if (clItemIndex !== -1) {
                const originalItem = list.checkItems[clItemIndex];
                if (originalItem) {
                    const toUpdateClItem = { ...originalItem, comment: text }
                    updateChecklist(clIndex, clItemIndex, toUpdateClItem)
                }
            }
        }
        const deleteImages = (clItemId: string, imageIndex: number | undefined = undefined) => {
            const clItemIndex = list.checkItems.findIndex(clItem => clItem.checklistItemId === clItemId)
            if (clItemIndex !== -1) {
                const originalItem = list.checkItems[clItemIndex];
                if (originalItem) {
                    const toUpdateClItem = { ...originalItem }
                    if (imageIndex !== undefined) {

                        const imagesBefore = originalItem.itemImageUrls.slice(0, imageIndex)
                        const imagesAfter = originalItem.itemImageUrls.slice(imageIndex + 1)

                        toUpdateClItem.itemImageUrls = [
                            ...imagesBefore,
                            ...imagesAfter]
                    } else {

                        toUpdateClItem.itemImageUrls = []
                    }

                    updateChecklist(clIndex, clItemIndex, toUpdateClItem)
                }

            }
        }

        const boxColor: {
            [key: string]: string
        } = {
            OK: "text-green-500",
            Dev: "text-red-600",
            NA: "text-gray-500",
            OKSelected: "bg-green-500",
            DevSelected: "bg-red-600",
            NASelected: "bg-gray-500"

        }

        return (
            <div>
                {openCam ? <NBKCamera submit={handleSubmitImage} close={handleClose} takeOne={false} /> : null}
                <ProjectTitle title={list.checklistName} history={history} />
                {list.checkItems.map(item => {
                    const { checklistItemId, question, status, comment, itemImageUrls } = item
                    return (
                        <Card
                            key={checklistItemId}
                            className="mb-4 p-0"
                            style={{ minWidth: 275 }}
                        >
                            < CardContent>
                                <FormControl style={{ width: "100%" }}>
                                    <p className="mb-4">{question}</p>
                                    <div className="flex justify-between mb-2">
                                        {[
                                            {
                                                option: "OK", label: "Godkjent"
                                            },
                                            {
                                                option: "Dev", label: "Avvik"
                                            },
                                            {
                                                option: "NA", label: "I/A"
                                            }
                                        ].map((box, i) => {
                                            return (
                                                <div className="flex relative justify-between" key={box.option}>
                                                    <label
                                                        className={`bg-gray flex items-center py-1 px-2 pr-3 rounded-lg ${box.option === status ? `text-white ${boxColor[`${box.option}Selected`]}` : boxColor[box.option]}`}
                                                        htmlFor={checklistItemId}
                                                        key={box.option}

                                                    >
                                                        <Typography
                                                            variant="caption"
                                                            className={`mx-2 ${box.option === status ? 'text-white' : ''}`}
                                                        >
                                                            {box.label}
                                                        </Typography>
                                                        <div className="pl-2">
                                                            {box.option !== "NA" ? <PhotoCamera /> : <Close />}
                                                        </div>
                                                        <input
                                                            className="absolute w-1/4 opacity-0 inset-0"
                                                            type="radio"
                                                            style={{ position: "absolute", width: "100px" }}
                                                            name={checklistItemId}
                                                            value={box.option}
                                                            checked={box.option === status}
                                                            onChange={(e) => { handleStatusChange(e, checklistItemId) }}
                                                        />

                                                    </label>
                                                </div>
                                            )
                                        }
                                        )}
                                    </div>
                                </FormControl >


                            </ CardContent>
                            <Divider />
                            <CardContent className="p-0">
                                <UpdateComment
                                    updateComment={(text: string) => onCommentChange(text, checklistItemId)}
                                    initialText={comment ? comment : ''}
                                />
                            </ CardContent>
                            <Divider />
                            <CardContent className="p-0">
                                {itemImageUrls && (
                                    <div className="grid grid-cols-3 gap-2 w-full">
                                        {itemImageUrls.map((item, k) => {
                                            const badgeContent = (
                                                <Close fontSize="small" />
                                            )
                                            return (
                                                <div key={shortid.generate()}>
                                                    <Badge style={{ zIndex: 0 }} badgeContent={badgeContent} color="error" onClick={() => deleteImages(checklistItemId, k)}>
                                                        <div>
                                                            <img alt='' className="w-full" src={item} />
                                                        </div>

                                                    </Badge >
                                                </div>
                                            )
                                        }
                                        )}
                                    </div>
                                )}
                            </CardContent>
                        </Card>)
                })
                }
            </div >
        )
    } else {
        return <Redirect to="/" />
    }
}

export default CheckLists


const UpdateComment: React.FC<{ updateComment: (text: string) => void, initialText?: string }> = ({ initialText, updateComment }) => {
    const [text, setText] = React.useState('')
    const [editComment, setEditComment] = React.useState(false)
    React.useEffect(() => {
        if (initialText) {
            setText(initialText)
        }
    }, [initialText])
    const handleButtonClick = () => {
        if (editComment) {
            updateComment(text)

        }
        setEditComment(!editComment)
    }

    const updateText = (e: any) => {
        setText(e.target.value)
    }
    return (
        <div>
            {editComment ? <input
                onChange={updateText}
                type="text"
                placeholder="Kommentar"
                className="w-full mb-4 border border-gray-400 p-2 rounded"
                value={text ? text : ''}
            /> : <p>{text}</p>}
            <button className="text-sm uppercase text-blue-700" onClick={handleButtonClick}>{editComment ? 'Submit' : (text === '' ? 'Add Comment' : 'Edit Comment')}</button>
            {editComment && <button className="text-sm uppercase pl-4" onClick={() => setEditComment(false)}>Cancel</button>}
        </div>
    )
}