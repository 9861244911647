import React, { useState, useEffect } from 'react'
import { RouteComponentProps } from 'react-router'
import allrequests, { requestNBK } from '../api'
import { useDispatch, useSelector } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import Update from '@material-ui/icons/Update';
import SaveAlt from '@material-ui/icons/SaveAlt';
import { projectsSelectorState } from '../store/selector'
import { unsetActiveProject } from '../store/activeProject'

import { dateToString } from '../util'
import { IProjectDetails, IProjectSummary, IProjectDetailRes, IChecklistRes } from '../types'
import Header from '../Components/Header'
import { addProjects } from '../store/project'
import { updateProjectDetails as updateProjectDetailsAction } from '../store/project'

interface ILoading {
    [key: string]: {
        icon: boolean,
        text: string | undefined
        error?: string | undefined
    }
}
const Projects: React.FC<RouteComponentProps> = ({ history, ...rest }) => {
    const [loading, setLoading] = useState<ILoading>({})
    const projects = useSelector(projectsSelectorState)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(unsetActiveProject());
        getAllProjects();
    }, [])

    const getAllProjects = () => {
        if (projects.length === 0) {
            requestNBK({ requestConfig: allrequests.getAllProjects })
                .then(res => {
                    const list = res.listOfProjects.map((p: IProjectSummary) => ({ summary: p }))
                    dispatch(addProjects(list))
                })
        }
    }

    const selectProject = (projectID: string, hasDetails: boolean) => {
        if (hasDetails) {
            history.push(`/project/overview/${projectID}`)
        } else {
            try {
                const updateLoading = { ...loading }
                updateLoading[projectID] = {
                    icon: true,
                    text: 'Henter prosjekt'
                }
                setLoading(updateLoading)

                return getProject(projectID).then((res) => {
                    updateLoading[projectID] = {
                        icon: false,
                        text: 'Ferdig'
                    }
                    setLoading(updateLoading)
                    history.push(`/project/overview/${projectID}`)
                })
            } catch (error) {
                console.log(error)
            }
        }


    }

    const getProject = async (projectID: string) => {

        try {
            let res: IProjectDetailRes | null = await requestNBK({ requestConfig: allrequests.getProjectDetails, body: ({ projectID }) })
                .catch((error:any) => {
                    const updateLoading = { ...loading }
                    updateLoading[projectID] = {
                        icon: true,
                        text: '',
                        error: error.message ? error.message : ""
                    }
                    setLoading(updateLoading)
                })
            if (res && res.response && res.response.status === "200") {
                const {
                    address,
                    floorPlanUrl: floorPlan,
                    leaderName,
                    leaderNumber,
                    flislegerName,
                    flislegerNumber,
                    siteImageUrl: exteriorImage,

                } = res.projectDetail



                const allChecklistsRquests = res.listOfChecklists.map(checklist => {
                    return requestNBK({ requestConfig: allrequests.getChecklist, body: ({ ChecklistID: checklist.checklistId }) })

                        .then((clItems: IChecklistRes) => {
                            if (clItems.response.status == "200") {
                                const { checklistId, checklistName } = checklist
                                return ({
                                    checklistId: checklistId.toString(),
                                    checklistName,
                                    checkItems: clItems.listOfChecklistItems.map((item: any) => ({
                                        checklistItemId: item.checklistItemID.toString(),
                                        question: item.question,
                                        status: item.status,
                                        comment: item.comment,
                                        itemImageUrls: item.ItemImageUrls
                                    }))
                                })
                            } else {
                                throw Error(`cannot find checklist ${checklist.checklistId}`)
                            }

                        })
                })

                const allCheckLists = await Promise.all(allChecklistsRquests)

                const transformedProject: IProjectDetails = {
                    projectID: `${projectID}`,
                    address,
                    floorPlan,
                    projectLeader: {
                        name: leaderName,
                        number: leaderNumber
                    },
                    flisLegger: {
                        name: flislegerName,
                        number: flislegerNumber
                    },
                    exteriorImage,
                    checklists: allCheckLists
                }

                if (floorPlan && floorPlan !== "") {
                    const floorPlanFile = await fetch(floorPlan).then(res => res.blob())
                    transformedProject.floorPlanFile = floorPlanFile
                }

                dispatch(updateProjectDetailsAction({ projectID, projectDetails: transformedProject }))
                return transformedProject
            } else if (res && res.response && res.response.message) {
                throw Error(res.response.message)
            } else {
                throw Error("Unable to fetch project details")
            }
        } catch (error:any) {

            const updateLoading = { ...loading }
            updateLoading[projectID] = {
                icon: true,
                text: '',
                error: error.message?error.message:""
            }
            setLoading(updateLoading)
            return error
        }
    }

    return (
        <div className="relative">
            <Header history={history} {...rest}>

                <div>
                    <Typography
                        color="textSecondary"
                        variant="subtitle1"
                        gutterBottom
                    >
                        Aktive prosjekter
                    </Typography>
                    {projects.map((project, key) => {
                        const { projectID, projectName, projectDetail, inspectionDate } = project.summary
                        const parsedDate = new Date(inspectionDate)
                        const formatedDate = dateToString(parsedDate)
                        return (
                            <Card className="mb-1 p-0" style={{ "minWidth": 275 }} key={key}>
                                <CardContent className="flex justify-space items-center" onClick={() => selectProject(projectID, project.details !== undefined)}>
                                    <div className="flex p-2" >
                                        <div className="flex flex-col justify-center">
                                            <Typography variant="button" className="text-primary" >
                                                {formatedDate}
                                            </Typography>
                                            <Typography className="text">{projectName}</Typography>
                                            <p className="text-sm">{projectDetail}</p>
                                            <p className="text-sm italic text-green-600">{loading[projectID] ? loading[projectID].text : ''}</p>
                                            <p className="text-sm italic text-red-600">{loading[projectID] ? loading[projectID].error : ''}</p>
                                        </div>
                                    </div>

                                    <div className="p-2" >
                                        {loading[projectID] && loading[projectID].icon
                                            ? <CircularProgress />
                                            : project.details ? <Update color="primary" /> : <SaveAlt color={"secondary"} />}

                                    </div>
                                </CardContent>
                            </Card>
                        )
                    }

                    )}
                </div>
            </Header>
        </div>)
}


export default Projects