import axios from 'axios';
/* import { showMessage } from 'app/store/fuse/messageSlice'; */
export interface IRequest {
    url: string
    method: string
}


// requestConfig: IRequest, body?: any
interface IRequestOptions {
    requestConfig: IRequest;
    body?: any;
    dispatch?: any;
}

export const requestNBK = (requestOptions: IRequestOptions) => {
    const { requestConfig, body, dispatch } = requestOptions;
    const url = `${requestConfig.url}`; //
    const options: any = {
        url,
        method: requestConfig.method
    };
    if (requestConfig.method === 'DELETE') {
        if (typeof body === 'object') {
            let { url } = options;
            const keys = Object.keys(body);
            let query = '?';
            for (const index in keys) {
                const key = keys[index];
                query += `${parseInt(index) === 0 ? '' : '&'}${key}=${body[key]}`;
            }

            url += query;
            options.url = url;
        }

        return axios
            .delete(options.url, { data: body })
            .then(res => processRes(res))
            .catch(error => processError(error, dispatch));
    }
    if (requestConfig.method === 'POST' || requestConfig.method === 'PUT') {
        options.data = body;
    }

    if (requestConfig.method === 'GET') {
        if (typeof body === 'object') {
            options.params = body;
        }
    }

    return axios(options)
        .then(res => processRes(res))
        .catch(error => processError(error, dispatch));
};

const processRes = (res: any) => {
    if (res.status === 401) {
        window.localStorage.removeItem('session_user_id');
        window.localStorage.removeItem('jwt_access_token');
        return (window.location.href = '/');
    }
    if (res.data) {
        return res.data;
    }
    return res;
};

const processError = (error: any, dispatch: any) => {
    console.log(error);
    let message = '';
    if (error.response && error.response.data) {
        console.log(error.response.data); // => the response payload
        const { data } = error.response;
        message += data.message;
    } else {
        message += error.message;
    }
    if (dispatch) {
        dispatch(
            /*             showMessage({
                            message,
                            options: {
                                variant: 'error'
                            }
                        }) */
        );
    } else {
        console.log(error);
    }
    throw new Error(message);
};

const requests = {
    login: {
        url: '/users/Authenticate',
        method: 'POST'
    },
    userProfile: {
        url: '/UserProfile/GetUserProfile',
        method: 'POST'
    },
    getAllProjects: {
        url: '/MobileApp/GetProjectList',
        method: 'GET'
    },
    getProjectDetails: {
        url: '/MobileApp/ProjectDetail',
        method: 'GET'
    },
    updateProject: {
        url: '/MobileApp/ProjectUpdate',
        method: 'POST'
    },
    getChecklist: {
        url: '/MobileApp/ChecklistItems',
        method: 'GET'
    },
    updateChecklist: {
        url: '/MobileApp/ChecklistUpdate',
        method: 'GET'
    },
    submitProject: {
        url: '/MobileApp/ProjectSubmit',
        method: 'POST'
    },
    logProject: {
        url: '/MobileApp/Log',
        method: 'GET'
    }
}

export default requests