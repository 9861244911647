import React, { useState } from 'react'
import { RouteComponentProps, Redirect } from 'react-router'
import { useDispatch } from 'react-redux';


import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import PhotoIcon from '@material-ui/icons/PhotoCamera';

import ProjectTitle from '../Components/ProjectTitle'
import NBKCamera from '../Components/NBKCamera'

import { IProject } from '../types'
import { updateProject as updateProjectAction } from '../store/project'
import { updateProfile } from '../util'

interface IProps extends RouteComponentProps {
    projectIndex: number,
    history: any,
    project: IProject
}

/* const mapDispatchtoProps = (dispatch: Dispatch) => ({ updateProject: (payload: IProject) => dispatch(updateProject(payload)) }) */

export const ProjectExterior: React.FC<IProps> = ({ history, project }) => {
    // load project

    const dispatch = useDispatch()
    const [openCam, setOpenCam] = useState(false);
    if (project.details) {
        const { projectID, projectName, projectDetail, inspectionDate } = project.summary
        const { exteriorImage } = project.details

        const handleSubmit = (img: string[]) => {
            const toUpdateProject = { ...project }
            toUpdateProject.details.exteriorImage = img[0]
            dispatch(updateProjectAction(toUpdateProject))
            return updateProfile(inspectionDate, projectDetail, projectID, img[0])
        }

        const handleOpen = () => {
            setOpenCam(true)
        }
        const handleClose = () => {
            setOpenCam(false)
        }

        return (
            <div>
                {openCam ? <NBKCamera takeOne={true} close={handleClose} submit={handleSubmit} /> : null}
                <ProjectTitle title={projectName} description={projectDetail} history={history} />
                <Card className="mb-2">
                    <CardActionArea >
                        <CardMedia
                            component="img"
                            image={exteriorImage ? exteriorImage : "https://source.unsplash.com/random/800x600"}
                            title="Dummy image"
                        />

                    </CardActionArea >
                    <div>
                        <Button className="w-full flex justify-center p-2" color="primary" onClick={handleOpen} >< PhotoIcon /> Ta bilde</Button >
                    </div>

                </Card>

            </div>
        )
    } else {
        return <Redirect to="/" />
    }
}



export default ProjectExterior