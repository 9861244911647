import React from 'react';
import { useSelector } from 'react-redux'
import Auth from './Auth'
import Login from './Screens/Login'
import Router from './Router/AppRoutes'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import './styles/tailwind-output.css'
import { loggedInSelector } from './store/selector'
function App() {
  const isLoggedIn = useSelector(loggedInSelector)

  const screen = isLoggedIn ? (<Router />) : <Login />
  return (
    <Auth>
      <MuiThemeProvider theme={theme}>
        {screen}
      </MuiThemeProvider>
    </Auth>
  );
}

export default App;

const theme = createMuiTheme({
  palette: {
    primary: { main: "#1b2330" },
    secondary: { main: "#1e2355" },
  },
});
