import React from 'react'
import { useDispatch } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { Link } from 'react-router-dom'

import { withStyles, createStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import Exit from '@material-ui/icons/ExitToApp';

import { useSwipeable } from 'react-swipeable'
import { logUserOut as logUserOutAction } from '../store/user'
import { clearProjects as clearProjectsAction } from '../store/project'
import JwtService from '../services/jwtService';

const ButtonAppBar: React.FC<RouteComponentProps> = ({ history, children }) => {
    const atHome = history.location.pathname === "/"
    const dispatch = useDispatch()
    /*     const AppStore = useContext(StoreContext); */
    const swipeConfig = {
        preventDefaultTouchmoveEvent: false,
        trackMouse: false
    }
    const swipeActions = {
        onSwipedRight: () => {
            if (atHome) {
                console.log('cannot go back')
            } else {
                history.back()
            }
        },
        onSwipedLeft: () => {
            history.forward();
        },

    }

    const handleLogout = () => {
        JwtService.logout()
        dispatch(logUserOutAction())
        dispatch(clearProjectsAction())
    }

    const handlers = useSwipeable({
        ...swipeConfig,
        ...swipeActions
    });
    return (
        <div className="w-full flex-1">
            <AppBar
                position="static"
                className="text-white bg-gray"
            >
                <Toolbar>
                    <Typography variant="h6" color="inherit" className="flex-1">
                        <Link to="/" className="text-white no-underline">Norsk ByggeKontroll</Link>
                    </Typography>
                    <IconButton color="inherit" aria-label="Menu" onClick={() => { handleLogout() }}>
                        <Exit />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <div {...handlers} >
                <div className="p-4">
                    {children}
                </div>
            </div>

        </div>
    );
}

export default ButtonAppBar;


const styles = createStyles({
    root: {
        flex: 1,
        width: "100%"
    },
    grow: {
        flexGrow: 1,
    },
    body: {
        display: "flex",
        height: "100%",
        flexDirection: "column",
        backgroundColor: "#f7f7f7"
    },
    link: {
        color: "#22d3ee",
        textDecoration: "none"
    },
    bodyInner: {
        padding: "1rem",
    }
});