import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { RouteComponentProps, Redirect } from 'react-router'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import LinearProgress from '@material-ui/core/LinearProgress';
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Close from '@material-ui/icons/Close';

import ProjectTitle from '../Components/ProjectTitle'

import { clearProjects as clearProjectsAction } from '../store/project'
import { IProject } from '../types'
import { updateChecklistItem, postRequestDateToString } from '../util'
import { deletePersistProject } from '../util/persis'
import allRequests, { requestNBK } from '../api'
interface IProps extends RouteComponentProps {
    classes: any,
    projectIndex: number,
    history: any
    project: IProject
    clearProjects: () => void
}

interface IChecklistUpdateProgress {
    [key: string]: {
        done: number,
        total: number
    }
}

interface ILogItem {
    Id: string
    projectID: string
    DateTime: string
}


const Submit: React.FC<IProps> = ({ classes, history, projectIndex, project }) => {
    const dispatch = useDispatch()
    const [progress, setProgress] = useState<IChecklistUpdateProgress>({})
    const [submit, setSubmit] = useState(false)
    const [comment, setComment] = useState<string>('')
    const [signature, setSignature] = useState<string>('')
    const [signatureError, setSignatureError] = useState(false)
    const [lastSubmitted, setLastSubmitted] = useState<string | null>(null)
    const { projectID } = project.summary
    const { checklists } = project.details

    useEffect(() => {
        const progressInitialState: IChecklistUpdateProgress = {}
        const cls = checklists
        for (let i = 0; i < cls.length; i++) {
            progressInitialState[cls[i].checklistId] = {
                done: 0,
                total: cls[i].checkItems.length
            }
        }
        progressInitialState["submit"] = {
            done: 0,
            total: 1
        }
        setProgress(progressInitialState)


    }, [checklists])

    useEffect(() => {
        getLog()
    }, [])
    const getLog = () => {
        return requestNBK({ requestConfig: allRequests.logProject, body: ({ projectID }) })
            .then(res => {
                if (res && res[0]) {
                    setLastSubmitted(res[0].dateTime)
                }
            })
    }

    const handleCommentChange = (e: any) => {
        setComment(e.target.value)
    }

    const handleSignatureChange = (e: any) => {
        setSignature(e.target.value)
    }

    const updateAll = async () => {
        if (signature && signature !== "") {
            setSubmit(true)
            setSignatureError(false)
            const result = await Promise.all(checklists.map(list => {
                return Promise.all(list.checkItems.map(clItem => {
                    const updated = clItem.updated ? Promise.resolve() : updateChecklistItem({ projectID: projectID, checklistId: list.checklistId, checklistItem: clItem })
                    return updated.then((res) => {
                        const toUpdate = { ...progress }
                        toUpdate[list.checklistId].done = toUpdate[list.checklistId].done + 1
                        setProgress(toUpdate)
                        return `${projectID}_${list.checklistId}_${clItem}_ok`
                    });
                }))
            }))
            const currentTime = new Date()

            const toSend = {
                "ProjectSubmit": {
                    "ProjectId": projectID,
                    "ProjectSubmitDate": currentTime.toISOString(),
                    "InspectorComments": comment,
                    "InspectorSignature": signature
                }
            }

            const submitResult = await requestNBK({ requestConfig: allRequests.submitProject, body: toSend })
            const toUpdate = { ...progress }
            toUpdate['submit'].done = 1
            setProgress(toUpdate)


            if (result && submitResult) {
                deletePersistProject(projectID)
                dispatch(clearProjectsAction())
                window.location.reload()
            }
        } else {
            setSignatureError(true)
        }
    }
    if (project.details) {
        const { checklists } = project.details
        return (
            <div >
                <ProjectTitle title={"Se gjennom og last opp"} history={history} />
                <Card className="mb-4 p-0">
                    <CardContent className="flex flex-col">
                        {checklists.map((item, key) =>
                            <div className="flex justify-space items-center p-0" key={key} onClick={() => history.push(`/project/single-list/${item.checklistId}/${projectID}`)}>
                                <div className="flex py-4" >
                                    {item.checklistName}
                                </div>
                                <p className="text-gray-600">{item.checkItems.filter(item => item.status !== undefined && item.status !== null).length}/{item.checkItems.length}</p>
                            </div>
                        )}

                        <div>
                            <input
                                onChange={(e) => handleCommentChange(e)}
                                type="textfield"
                                placeholder="Kommentar"
                                className={"w-full mb-4 border border-gray-400 p-2 rounded"}
                                value={comment}
                            />
                        </div>
                    </CardContent>
                </Card>
                <div>
                    {signatureError && <span className="rounded p-2 text-red-600">{signatureError}</span>}
                    <input
                        onChange={(e) => handleSignatureChange(e)}
                        placeholder="Signature"
                        className={"w-full mb-4 border-b border-gray-400 p-2 "}
                        value={signature}
                    />
                </div>
                <Dialog fullWidth open={submit}>
                    <DialogTitle onClick={() => { setSubmit(false) }}>
                        <Close />
                    </DialogTitle>
                    <DialogContent>
                        {checklists.map((item, key) => {
                            return (
                                <div style={{ marginBottom: "1rem" }}>
                                    <Typography style={{ fontSize: "10px" }} variant="subtitle2" className="text" color="secondary">{item.checklistName}</Typography>
                                    <LinearProgress variant="determinate" color="primary" value={progress[item.checklistId] ? (progress[item.checklistId].done / progress[item.checklistId].total) * 100 : 0} />
                                </div>
                            )

                        })

                        }
                        <div style={{ marginBottom: "1rem" }}>
                            <Typography style={{ fontSize: "10px" }} variant="subtitle2" className="text" color="secondary">Submit comments</Typography>
                            <LinearProgress variant="determinate" color="primary" value={progress.submit ? (progress.submit.done / progress.submit.total) * 100 : 0} />
                        </div>
                    </DialogContent>
                </Dialog>
                <Button onClick={updateAll} color="primary" variant="contained" style={{ color: "white", width: "100%", marginTop: "2rem", padding: "0.5rem" }}>Last opp</Button>
                {lastSubmitted && <span className="w-full text-center text-sm text-gray-600 italic p-2">Last submitted: {lastSubmitted}</span>}
            </div>
        )
    } else {
        return <Redirect to="/" />
    }


}
/* 
const styles = createStyles({
    content: {
        display: "flex",
        flexDirection: "column"
    },
    eachList: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0",
        "&:last-child": {
            padding: "0"
        }
    },
    contentLeft: {
        display: "flex",
        padding: "0.5rem"
    },
    card: {
        marginBottom: "1rem",
        minWidth: 275,
        padding: "0"
    },
    action: {
        padding: "0.5rem"
    },
    comment: {
        width: "100%",
        margin: "1rem 0 0 0",
        border: "solid 1px lightgrey",
        padding: "0.5rem",
        boxSizing: "border-box"
    }
}) */

export default Submit