import React from 'react'
import { Switch, Route } from 'react-router-dom'

import WithLayoutRoute from './WithLayoutRoute'
import ProjectInfo from '../Screens/ProjectInfo'
import ProjectExterior from '../Screens/ProjectExterior'
import SingleList from '../Screens/SingleCheckList'
import AllLists from '../Screens/AllCheckLists'
import Submit from '../Screens/Submit'


const projectRouteConfig = [
    {
        path: '/overview',
        component: ProjectInfo
    },
    {
        path: '/exterior',
        component: ProjectExterior
    },
    {
        path: '/all-lists',
        component: AllLists
    },
    {
        path: '/single-list/:checklistId',
        component: SingleList
    },
    {
        path: '/submit',
        component: Submit
    }
]



const ProjectRoutes: React.FC<any> = (props) => {
    return (
        <Switch>
            {projectRouteConfig.map((item, key) => {
                return (
                    <WithLayoutRoute
                        key={key}
                        path={`/project${item.path}/:projectID`}
                        component={item.component}
                    />
                )
            })}
            <Route component={ProjectInfo} />
        </Switch>
    )
}

export default ProjectRoutes
